import { OrderData } from '../components/dashboard/orders/UserOrders';
import { Countries } from './Countries';

export const parseOrderData = async (
  data: OrderData[]
): Promise<{ data: OrderData[]; skuMap: Map<string, string> }> => {
  const dRows: OrderData[] = [];
  const skuMapTemp = new Map<string, string>();
  skuMapTemp.set('SEN_HEADSET_V1', 'SEN_HEADSET_V1');
  skuMapTemp.set('SEN_HEADSET_REFURBV1', 'SEN_HEADSET_REFURBV1');
  await Promise.all(
    data.map(async (d) => {
      const skus = {
        SEN_HEADSET_V1: 0,
        SEN_HEADSET_REFURBV1: 0,
        sku3Quantity: 0,
        sku4Quantity: 0,
        sku5Quantity: 0,
        lineAmount: 0,
        shippingAmount: 0,
        tax1: 0,
        tax2: 0,
        tax3: 0,
        tax4: 0,
        tax5: 0
      };

      const lineItems = [...d.lineItems];
      lineItems.sort((a, b) =>
        (a.lineType + a.description).localeCompare(b.lineType + b.description)
      );
      let taxCount = 1;
      lineItems.forEach((l) => {
        if (
          l.lineType === 'product_shippable' ||
          l.lineType === 'product_none_shippable'
        ) {
          const prop = !skuMapTemp.has(l.sku)
            ? `sku${skuMapTemp.size + 1}Quantity`
            : skuMapTemp.get(l.sku);
          skus[prop] = l.quantity - l.refundQuantity - l.returnQuantity;
          skuMapTemp.set(l.sku, prop);
          skus.lineAmount += l.unitAmount * (l.quantity - l.refundQuantity);
        }
        if (l.lineType === 'discount') {
          skus.lineAmount =
            l.unitAmount < 0
              ? l.unitAmount + skus.lineAmount
              : skus.lineAmount - l.unitAmount;
        }
        if (l.lineType === 'shipping') {
          skus.shippingAmount += l.unitAmount;
        }
        if (l.lineType === 'sales_tax') {
          skus['tax' + taxCount] = l.unitAmount;
          taxCount++;
        }
      });

      const shippableLine = lineItems.find(
        (l) => l.lineType === 'product_shippable'
      );
      let valid = true;
      let hashedAudience: any = {};
      if (shippableLine) {
        const country = d.shippingAddress?.country;

        const countryInfo = country
          ? Countries.get(capitalizeFirstLetter(country))
          : null;

        hashedAudience.country = countryInfo?.code;
        hashedAudience.phone = await setAndBuildPhoneFormat(
          country,
          d.shippingAddress?.phone
        );

        hashedAudience.firstName = await digestMessage(
          d.shippingAddress?.firstName.trim().toLowerCase()
        );
        hashedAudience.lastName = await digestMessage(
          d.shippingAddress?.lastName.trim().toLowerCase()
        );
        hashedAudience.email = await digestMessage(
          d.email.trim().toLowerCase()
        );

        const province = d.shippingAddress?.province;
        const postal = d.shippingAddress?.postal;
        if (!country || (!province && !countryInfo?.isEU) || !postal) {
          valid = false;
        } else {
          if (
            countryInfo &&
            countryInfo.postalRegex &&
            countryInfo.postalRegex !== ''
          ) {
            const ex = new RegExp(countryInfo.postalRegex);
            valid = ex.test(postal);
          }
        }
      }
      dRows.push({
        ...d,
        ...skus,
        hashedAudience,
        shippingAddressValidationFlag: !valid
      });
    })
  );
  return { data: dRows, skuMap: skuMapTemp };
};

const digestMessage = async (message: string): Promise<string> => {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const setAndBuildPhoneFormat = (country?: string, phone?: string) => {
  let ph = phone.trim(); // clean & hash
  ph = ph.replace(/\D/g, ''); //remove non numbers

  if (country && phone) {
    const countryCodeList = Countries.get(
      capitalizeFirstLetter(country)
    )?.countryCallingCodes;
    if (countryCodeList && countryCodeList.length > 0) {
      const countryCode = countryCodeList[0].split(' ')[0];
      const countryCodeNum = countryCode.replace('+', '');

      if (!ph.startsWith(countryCodeNum)) {
        ph = countryCodeNum + ph;
      }
    }
  }
  return digestMessage('+' + ph);
};
